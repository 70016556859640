<template>
  <table cellspacing="0" cellpadding="0" border="0" :width="signatureWidth" :style="{ backgroundColor: descriptionBackground, fontFamily: fontFamily }">
    <tr>
      <td style="padding: 20px 0;">
        <table cellspacing="0" cellpadding="0" border="0" align="center">
          <tr>
            <td :style="{ 'border-right': `1px solid ${borderColor}`, 'padding': '0 25px'}">
              <table cellspacing="0" cellpadding="0" border="0" align="center">
                <tr v-if="showProfileImage">
                  <td align="center">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <tr>
                        <td align="left" :width="profileImageSize" :height="profileImageSize">
                          <a v-if="profileImageHref" :href="formatHref(profileImageHref)" target="_blank">
                            <img alt="Profile" :src="profileImage" 
                              :width="profileImageSize" 
                              :height="profileImageSize" 
                              :style="{ 
                                'border-radius': `${profileImageShape}%`
                              }"
                              style="max-width: 100%; height: auto; object-fit: cover; border: none; display: block; margin: 0 auto;">
                          </a>
                          <img alt="Profile" v-else :src="profileImage" 
                              :width="profileImageSize" 
                              :height="profileImageSize" 
                              :style="{ 
                                'border-radius': `${profileImageShape}%`
                              }"
                              style="max-width: 100%; height: auto; object-fit: cover; border: none; display: block; margin: 0 auto;">
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="name || jobTitle">
                  <td align="center" style="padding-top: 20px;">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <tr v-if="name">
                        <td align="center">
                          <span :style="{ fontFamily: fontFamily, color: mainColor, fontSize: `${computedFontSize + 3}px` }"><strong>{{ name }}</strong></span>
                        </td>
                      </tr>
                      <tr v-if="jobTitle">
                        <td align="center">
                          <span :style="{ fontFamily: fontFamily, color: secondaryColor, fontSize: `${computedFontSize}px` }">{{ jobTitle }}</span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="icons.length > 0">
                  <td align="center" style="padding-top: 15px;">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <tr>
                        <td v-for="icon in icons" :key="icon" style="padding-right: 15px;">
                          <a v-if="socialLinks[icon]" :href="formatHref(socialLinks[icon])" target="_blank" :style="{ 'display': 'inline-block', 'line-height': '0', 'text-decoration': 'none' }">
                            <table cellspacing="0" cellpadding="0" border="0">
                              <tr>
                                <td :style="{ 'padding': '7px', 'border-radius': `${socialIconShape}%`, backgroundColor: socialIconColor }">
                                  <img :src="getIconPath(icon)" :alt="icon" :width="socialIconSize" :height="socialIconSize" style="display: block;">
                                </td>
                                <td v-if="socialIconText" style="padding-left: 5px; vertical-align: middle;">
                                  <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: secondaryColor }">{{ formatIconName(icon) }}</span>
                                </td>
                              </tr>
                            </table>
                          </a>
                          <a v-else target="_blank" :style="{ 'display': 'inline-block', 'line-height': '0', 'text-decoration': 'none' }">
                            <table cellspacing="0" cellpadding="0" border="0">
                              <tr>
                                <td :style="{ 'padding': '7px', 'border-radius': `${socialIconShape}%`, backgroundColor: socialIconColor }">
                                  <img :src="getIconPath(icon)" :alt="icon" :width="socialIconSize" :height="socialIconSize" style="display: block;">
                                </td>
                                <td v-if="socialIconText" style="padding-left: 5px; vertical-align: middle;">
                                  <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: secondaryColor }">{{ formatIconName(icon) }}</span>
                                </td>
                              </tr>
                            </table>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
      <td :style="{ 'padding': '0 10px 0 20px'}">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td>
              <table cellspacing="0" cellpadding="0" border="0">
                <tr v-if="mobileNumber">
                  <td>
                    <table>
                      <tr>
                        <td v-if="mobileIcon" style="padding-right: 7px;">
                          <img v-if="mobileIcon" :src="mobileIcon" width="20" height="20" alt="Mobile" style="vertical-align: middle;"/>
                        </td>
                        <td v-else style="padding-right: 7px;">
                          <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{ mobileLabel + ' ' }}</strong></span>
                        </td>
                        <td>
                          <a :href="`tel:${formatMobileNumber(mobileNumber)}`" target="_blank" :style="{ textDecoration: 'none' }">
                            <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: secondaryColor, verticalAlign: middle }">
                              {{ mobileNumber }}
                            </span>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="phoneNumber">
                  <td>
                    <table>
                      <tr>
                        <td v-if="phoneIcon" style="padding-right: 7px;">
                          <img :src="phoneIcon" width="20" height="20" alt="Phone" style="vertical-align: middle;"/>
                        </td>
                        <td v-else style="padding-right: 7px;">
                          <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{ phoneLabel + ' ' }}</strong></span>
                        </td>
                        <td>
                          <a :href="`tel:${formatPhoneNumber(phoneNumber)}`" target="_blank" :style="{ textDecoration: 'none' }">
                            <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: secondaryColor, verticalAlign: middle }">
                              {{ phoneNumber }}
                            </span>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="emailAddress">
                  <td>
                    <table>
                      <tr>
                        <td v-if="emailIcon" style="padding-right: 7px;">
                          <img v-if="emailIcon" :src="emailIcon" width="20" height="20" alt="Email" style="vertical-align: middle;"/>
                        </td>
                        <td v-else style="padding-right: 7px;">
                          <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{ emailLabel + ' ' }}</strong></span>
                        </td>
                        <td>
                          <a :href="`mailto:${emailAddress}`" target="_blank" :style="{ textDecoration: 'none' }">
                            <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: secondaryColor, verticalAlign: middle }">
                              {{ emailAddress }}
                            </span>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-for="(field, index) in additionalFields" :key="index">
                  <td>
                    <table v-if="field.href">
                      <tr>
                        <td v-if="field.icon" style="padding-right: 7px;">
                          <img v-if="field.icon" :src="field.icon" width="20" height="20" :alt="field.label" style="vertical-align: middle;"/>
                        </td>
                        <td v-else style="padding-right: 7px;">
                          <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{ field.label + ' ' }}</strong></span>
                        </td>
                        <td>
                          <a v-if="field.href" :href="formatHref(field.href, field.hrefType)" target="_blank" :style="{ textDecoration: 'none' }">
                            <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: secondaryColor, verticalAlign: middle }">
                              {{ field.value }}
                            </span>
                          </a>
                        </td>
                      </tr>
                    </table>
                    <table v-else style="padding-right: 7px;">
                      <tr>
                        <td v-if="field.icon" style="padding-right: 7px;">
                          <img v-if="field.icon" :src="field.icon" width="20" height="20" :alt="field.label" style="vertical-align: middle;"/>
                        </td>
                        <td v-else>
                          <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: mainColor }"><strong>{{ field.label + ' ' }}</strong></span>
                        </td>
                        <td>
                          <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: secondaryColor, verticalAlign: middle }">
                            {{ field.value }}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="feedback && feedback.question">
                  <td style="padding-top: 5px">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <tr>
                        <td>
                          <p :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize}px` }">{{ feedback.question }}</p>
                        </td>
                      </tr>
                      <tr>
                        <td align="center" style="padding-top: 5px">
                          <a v-if="feedback.likeLink" :href="formatHref(feedback.likeLink)" target="_blank" :style="{ textDecoration: 'none' }">
                            <img :src="feedback.likeIcon" width="24" height="24" alt="Like" style="padding-right: 5px;">
                          </a>
                          <img v-else :src="feedback.likeIcon" width="24" height="24" alt="Like" style="padding-right: 5px;">
                          <a v-if="feedback.dislikeLink" :href="formatHref(feedback.dislikeLink)" target="_blank" :style="{ textDecoration: 'none' }">
                            <img :src="feedback.dislikeIcon" width="24" height="24" alt="Dislike">
                          </a>
                          <img v-else :src="feedback.dislikeIcon" width="24" height="24" alt="Dislike">
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr v-if="meetingChat.meetingImageUrl || meetingChat.chatText">
                  <td>
                    <table v-if="meetingChat.meetingImageUrl" cellspacing="0" cellpadding="0" border="0">
                      <tr>
                        <td style="padding-top: 20px">
                          <a v-if="meetingChat.meetingImageHref" :href="formatHref(meetingChat.meetingImageHref)" target="_blank" :style="{ textDecoration: 'none' }">
                            <img :src="meetingChat.meetingImageUrl" alt="Meeting" :width="meetingWidth">
                          </a>
                          <img v-else :src="meetingChat.meetingImageUrl" alt="Meeting" :width="meetingWidth">
                        </td>
                      </tr>
                    </table>
                    <table v-if="meetingChat.chatText" cellspacing="0" cellpadding="0" border="0">
                      <tr>
                        <td style="padding-top: 5px">
                          <table>
                            <tr>
                              <td style="padding-right: 5px;">
                                <img :src="meetingChat.chatTextIcon" width="20" height="20" alt="Chat" style="vertical-align: middle;"/>
                              </td>
                              <td v-if="meetingChat.chatTextHref">
                                <a :href="formatHref(meetingChat.chatTextHref)" target="_blank" :style="{ textDecoration: 'none' }">
                                  <span :style="{ fontFamily: fontFamily, color: chatColor, fontSize: `${computedFontSize}px`, verticalAlign: middle }">
                                    <strong>{{ meetingChat.chatText }}</strong>
                                  </span>
                                </a>
                              </td>
                              <td v-else>
                                <span :style="{ fontFamily: fontFamily, color: chatColor, fontSize: `${computedFontSize}px`, verticalAlign: middle }">
                                  <strong>{{ meetingChat.chatText }}</strong>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table v-if="plannedAbsence.text || plannedAbsence.date" cellspacing="0" cellpadding="0" border="0" :width="signatureWidth">
    <tr>
      <td :style="{ 'padding': '20px 0'}">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td :style="{ 'padding' : '8px 16px', 'border-radius' : '8px', width: signatureWidth + 'px', backgroundColor: plannedAbsenceColor }">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr>
                  <td>
                    <span v-if="plannedAbsence.text" :style="{ fontFamily: fontFamily, color: plannedAbsenceTextColor, fontSize: `${computedFontSize}px`, width: signatureWidth + 'px' }">{{ plannedAbsence.text }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span v-if="plannedAbsence.date" :style="{ fontFamily: fontFamily, color: plannedAbsenceTextColor, fontSize: `${computedFontSize}px`, width: signatureWidth + 'px' }">{{ plannedAbsence.date }}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table v-if="showLogo || companyName || companyAddress" :width="signatureWidth" cellspacing="0" cellpadding="0" border="0">
    <tr>
      <td :style="{ 'border-top': `1px solid ${borderColor}`, 'padding-top': '20px'}">
        <table cellspacing="0" cellpadding="0" border="0" :width="signatureWidth">
          <tr>
            <td align="left" v-if="companyName || companyAddress" style="padding-right: 30px;">
              <table cellspacing="0" cellpadding="0" border="0">
                <tr v-if="companyName">
                  <td>
                    <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: '#000000' }">
                      <strong>{{ companyName }}</strong>
                    </span>
                  </td>
                </tr>
                <tr v-if="companyAddress">
                  <td>
                    <span :style="{ fontFamily: fontFamily, fontSize: `${computedFontSize}px`, color: '#000000' }">
                      {{ companyAddress }}
                    </span>
                  </td>
                </tr>
              </table>
            </td>
            <td align="right" v-if="showLogo">
              <a v-if="logoImageHref" :href="formatHref(logoImageHref)" target="_blank">
                <img :src="logoImage" alt="Logo" :width="logoSize" :style="{ width: `${logoSize}px`, display: 'block' }">
              </a>
              <img v-else :src="logoImage" alt="Logo" :width="logoSize" :style="{ width: `${logoSize}px`, display: 'block' }">
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <table v-if="link.name" :width="signatureWidth" cellspacing="0" cellpadding="0" border="0">
    <tr>
      <td style="padding-top: 10px">
        <a v-if="link.url" :href="formatHref(link.url)" target="_blank" :style="{ textDecoration: 'none' }">
          <span :style="{ fontFamily: fontFamily, color: addonsColor }">{{ link.name }}</span>
        </a>
        <span v-else :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize}px` }">
          {{ link.name }}
        </span>
      </td>
    </tr>
  </table>
  <table v-if="banner.url" cellspacing="0" cellpadding="0" border="0" :width="signatureWidth">
    <tr>
      <td style="padding-top: 20px">
        <a v-if="banner.href" :href="formatHref(banner.href)" target="_blank" :style="{ textDecoration: 'none' }">
          <img :src="banner.url" alt="Banner" :width="bannerWidth">
        </a>
        <img v-else :src="banner.url" alt="Banner" :width="bannerWidth">
      </td>
    </tr>
  </table>
  <table v-if="disclaimer" cellspacing="0" cellpadding="0" border="0" :width="tableWidth">
    <tr>
      <td style="padding-top: 10px">
        <span v-html="disclaimer" :style="{ fontFamily: fontFamily, color: addonsColor, fontSize: `${computedFontSize - 2}px` }"></span>
      </td>
    </tr>
  </table>
</template>
  
<script>
export default {
  props: {
    name: String,
    jobTitle: String,
    companyName: String,
    companyAddress: String,
    phoneNumber: String,
    phoneLabel: String,
    phoneIcon: String,
    mobileNumber: String,
    mobileLabel: String,
    mobileIcon: String,
    emailAddress: String,
    emailLabel: String,
    emailIcon: String,
    mainColor: String,
    secondaryColor: String,
    selectedIcons: Array,
    socialIconText: Boolean,
    socialLinks: Object,
    iconLinks: Object,
    profileImage: String,
    profileImageHref: String,
    descriptionBackground: String,
    borderColor: String,
    signatureWidth: Number,
    showProfileImage: Boolean,
    profileImageSize: Number,
    profileImageShape: Number,
    fontFamily: String,
    fontSize: String,
    additionalFields: Array,
    template: String,
    logoImage: String,
    logoImageHref: String,
    showLogo: Boolean,
    disclaimerWidth: Boolean,
    logoSize: Number,
    socialIconColor: String,
    socialIconShape: Number,
    socialIconSize: Number,
    addonsColor: String,
    chatColor: String,
    plannedAbsenceColor: String,
    plannedAbsenceTextColor: String,
    bannerWidth: Number,
    meetingWidth: Number,
    feedback: Object,
    link: {
      type: Object,
      default: () => ({})
    },
    banner: {
      type: Object,
      default: () => ({})
    },
    disclaimer: {
      type: String,
      default: ''
    },
    plannedAbsence: {
      tpye: Object,
      default: ''
    },
    meetingChat: {
      type: Object,
      default: () => ({})
    },
    customIcons: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tableWidth() {
      return this.disclaimerWidth ? '100%' : `${this.signatureWidth}px`;
    },
    icons() {
      return this.selectedIcons;
    },
    computedFontSize() {
      switch (this.fontSize) {
        case 'small':
          return 12;
        case 'medium':
          return 14;
        case 'large':
          return 16;
        default:
          return 14;
      }
    }
  },
  methods: {
    getIconPath(iconName) {
      if (this.iconLinks && this.iconLinks[iconName]) {
        return this.iconLinks[iconName];
      }
      const customIcon = this.customIcons.find(icon => icon.name === iconName);
      return customIcon ? customIcon.url : '';
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/\s+/g, '');
    },
    formatMobileNumber(mobileNumber) {
      return mobileNumber.replace(/\s+/g, '');
    },
    formatHref(href, hrefType) {
      if (!href) return '';
      switch (hrefType) {
        case 'tel':
          return `tel:${href}`;
        case 'mailto':
          return `mailto:${href}`;
        default:
          if (!/^https?:\/\//i.test(href)) {
            return `https://${href}`;
          }
          return href;
      }
    },
    formatIconName(icon) {
      return icon.charAt(0).toUpperCase() + icon.slice(1);
    }
  }
};
</script>