<template>
  <div class="signature-addons">
    <div class="header">
      <h2>Signature Addons</h2>
    </div>
    <div class="options-container">

      <!-- Meeting/Chat Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('meetingChat')">
          <span>Meeting / Chat</span>
          <button @click.stop="clearMeetingChat" class="btn-remove">✖</button>
        </div>
        <div :class="['addon-form', 'form-option', { 'active': sections.meetingChat }]" ref="meetingChatSection">
          <div >
            <div class="option">
              <label for="meeting-image-url">Meeting Image URL:</label>
              <input v-model="meetingImageUrl" @input="sanitizeMeetingChatInput" id="meeting-image-url" type="url" class="text-input" placeholder="Enter meeting image URL">
            </div>
            <div class="option">
              <input v-model="meetingImageHref" @input="sanitizeMeetingChatInput" id="meeting-image-href" type="url" class="text-input" placeholder="Enter meeting href">
            </div>
          </div>
          <div>
            <div class="option">
              <label for="chat-text">Chat Text:</label>
              <input v-model="chatTextIcon" @input="sanitizeMeetingChatInput" id="chat-text-icon" type="text" class="text-input" placeholder="Enter chat icon URL">
            </div>
            <div class="option">
              <input v-model="chatText" @input="sanitizeMeetingChatInput" id="chat-text" type="text" class="text-input" placeholder="Enter chat text">
            </div>
            <div class="option">
              <input v-model="chatTextHref" @input="sanitizeMeetingChatInput" id="chat-text-href" type="url" class="text-input" placeholder="Enter chat href">
            </div>
          </div>
        </div>
      </div>

      <!-- Link Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('link')">
          <span>Link</span>
          <button @click.stop="clearLink" class="btn-remove">✖</button>
        </div>
        <div :class="['addon-form', 'form-option', { 'active': sections.link }]" ref="linkSection">
          <div class="option">
            <label for="link-name">Link Name:</label>
            <input v-model="linkName" @input="sanitizeLinkInput" id="link-name" type="text" class="text-input" placeholder="Enter link name">
          </div>
          <div class="option">
            <label for="link-url">Link URL:</label>
            <input v-model="linkUrl" @input="sanitizeLinkInput" id="link-url" type="url" class="text-input" placeholder="Enter link URL">
          </div>
        </div>
      </div>

      <!-- Banner Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('banner')">
          <span>Banner</span>
          <button @click.stop="clearBanner" class="btn-remove">✖</button>
        </div>
        <div :class="['addon-form', 'form-option', { 'active': sections.banner }]" ref="bannerSection">
          <div class="option">
            <label for="banner-url">Banner URL:</label>
            <input v-model="bannerUrl" @input="sanitizeBannerInput" id="banner-url" type="url" class="text-input" placeholder="Enter banner image URL">
          </div>
          <div class="option">
            <label for="banner-href">Banner href:</label>
            <input v-model="bannerHref" @input="sanitizeBannerInput" id="banner-href" type="url" class="text-input" placeholder="Enter banner href">
          </div>
        </div>
      </div>

      <!-- Disclaimer Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('disclaimer')">
          <span>Disclaimer</span>
          <button @click.stop="clearDisclaimer" class="btn-remove">✖</button>
        </div>
        <div :class="['addon-form', 'form-option', { 'active': sections.disclaimer }]" ref="disclaimerSection">
          <div class="option">
            <label for="disclaimer">Disclaimer:</label>
            <textarea v-model="disclaimer" @input="sanitizeDisclaimerInput" id="disclaimer" class="text-input" placeholder="Enter disclaimer Text or HTML"></textarea>
          </div>
        </div>
      </div>

      <!-- Feedback Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('feedback')">
          <span>Feedback</span>
          <button @click.stop="clearFeedback" class="btn-remove">✖</button>
        </div>
        <div :class="['addon-form', 'form-option', { 'active': sections.feedback }]" ref="feedbackSection">
          <div class="option">
            <label for="question">Question:</label>
            <input v-model="feedbackQuestion" @input="sanitizeFeedbackInput" id="question" type="text" class="text-input" placeholder="Enter your question here">
          </div>
          <div class="option">
            <label for="like-link">Link to form:</label>
            <div class="option">
              <input v-model="feedbackLikeIcon" @input="sanitizeFeedbackInput" id="like-icon" type="text" class="text-input" placeholder="Enter the like icon URL">
            </div>
            <div class="input-icon">
              <div class="icon-container">
                <img :src="feedbackLikeIcon" class="icon-square">
              </div>
              <input v-model="feedbackLikeLink" @input="sanitizeFeedbackInput" id="like-link" type="text" class="text-input with-icon" placeholder="Enter the link for like">
            </div>
          </div>
          <div class="option">
            <label for="dislike-link">Link to form:</label>
            <div class="option">
              <input v-model="feedbackDislikeIcon" @input="sanitizeFeedbackInput" id="dislike-icon" type="text" class="text-input" placeholder="Enter the dislink icon URL">
            </div>
            <div class="input-icon">
              <div class="icon-container">
                <img :src="feedbackDislikeIcon" class="icon-square">
              </div>
              <input v-model="feedbackDislikeLink" @input="sanitizeFeedbackInput" id="dislike-link" type="text" class="text-input with-icon" placeholder="Enter the link for dislike">
            </div>
          </div>
        </div>
      </div>

      <!-- Planned Absence Section -->
      <div class="option">
        <div class="dropdown-header" @click="toggleSection('plannedAbsence')">
          <span>Planned Absence</span>
          <button @click.stop="clearPlannedAbsence" class="btn-remove">✖</button>
        </div>
        <div :class="['addon-form', 'form-option', { 'active': sections.plannedAbsence }]" ref="plannedAbsenceSection">
          <div class="option">
            <label for="absence-text">Absence Text:</label>
            <input v-model="absenceText" @input="sanitizePlannedAbsenceInput" id="absence-text" type="text" class="text-input" placeholder="Enter absence text">
          </div>
          <div class="option">
            <label for="absence-date">Absence Date:</label>
            <input v-model="absenceDate" @input="sanitizePlannedAbsenceInput" id="absence-date" type="text" class="text-input" placeholder="Enter absence date">
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    formData: Object,
  },
  data() {
    return {
      sections: {
        link: false,
        banner: false,
        disclaimer: false,
        feedback: false,
        meetingChat: false,
        plannedAbsence: false,
      },
    };
  },
  computed: {
    linkName: {
      get() {
        return this.formData.link?.name || '';
      },
      set(value) {
        this.$emit('update-link', { name: value, url: this.linkUrl });
      },
    },
    linkUrl: {
      get() {
        return this.formData.link?.url || '';
      },
      set(value) {
        this.$emit('update-link', { name: this.linkName, url: value });
      },
    },
    bannerUrl: {
      get() {
        return this.formData.banner?.url || '';
      },
      set(value) {
        this.$emit('update-banner', { url: value, href: this.bannerHref });
      },
    },
    bannerHref: {
      get() {
        return this.formData.banner?.href || '';
      },
      set(value) {
        this.$emit('update-banner', { url: this.bannerUrl, href: value });
      },
    },
    disclaimer: {
      get() {
        return this.formData.disclaimer || '';
      },
      set(value) {
        this.$emit('update-disclaimer', value);
      },
    },
    feedbackQuestion: {
      get() {
        return this.formData.feedback?.question || '';
      },
      set(value) {
        this.$emit('update-feedback', {
          question: value,
          likeIcon: this.feedbackLikeIcon,
          likeLink: this.feedbackLikeLink,
          dislikeIcon: this.feedbackDislikeIcon,
          dislikeLink: this.feedbackDislikeLink,
        });
      },
    },
    feedbackLikeIcon: {
      get() {
        return this.formData.feedback?.likeIcon || '';
      },
      set(value) {
        this.$emit('update-feedback', {
          question: this.feedbackQuestion,
          likeIcon: value,
          likeLink: this.feedbackLikeLink,
          dislikeIcon: this.feedbackDislikeIcon,
          dislikeLink: this.feedbackDislikeLink,
        });
      },
    },
    feedbackLikeLink: {
      get() {
        return this.formData.feedback?.likeLink || '';
      },
      set(value) {
        this.$emit('update-feedback', {
          question: this.feedbackQuestion,
          likeIcon: this.feedbackLikeIcon,
          likeLink: value,
          dislikeIcon: this.feedbackDislikeIcon,
          dislikeLink: this.feedbackDislikeLink,
        });
      },
    },
    feedbackDislikeIcon: {
      get() {
        return this.formData.feedback?.dislikeIcon || '';
      },
      set(value) {
        this.$emit('update-feedback', {
          question: this.feedbackQuestion,
          likeIcon: this.feedbackLikeIcon,
          likeLink: this.feedbackLikeLink,
          dislikeIcon: value,
          dislikeLink: this.feedbackDislikeLink,
        });
      },
    },
    feedbackDislikeLink: {
      get() {
        return this.formData.feedback?.dislikeLink || '';
      },
      set(value) {
        this.$emit('update-feedback', {
          question: this.feedbackQuestion,
          likeIcon: this.feedbackLikeIcon,
          likeLink: this.feedbackLikeLink,
          dislikeIcon: this.feedbackDislikeIcon,
          dislikeLink: value,
        });
      },
    },
    meetingImageUrl: {
      get() {
        return this.formData.meetingChat?.meetingImageUrl || '';
      },
      set(value) {
        this.$emit('update-meeting-chat', {
          meetingImageUrl: value,
          meetingImageHref: this.meetingImageHref,
          chatTextIcon: this.chatTextIcon,
          chatText: this.chatText,
          chatTextHref: this.chatTextHref,
        });
      },
    },
    meetingImageHref: {
      get() {
        return this.formData.meetingChat?.meetingImageHref || '';
      },
      set(value) {
        this.$emit('update-meeting-chat', {
          meetingImageUrl: this.meetingImageUrl,
          meetingImageHref: value,
          chatTextIcon: this.chatTextIcon,
          chatText: this.chatText,
          chatTextHref: this.chatTextHref,
        });
      },
    },
    chatTextIcon: {
      get() {
        return this.formData.meetingChat?.chatTextIcon || '';
      },
      set(value) {
        this.$emit('update-meeting-chat', {
          meetingImageUrl: this.meetingImageUrl,
          meetingImageHref: this.meetingImageHref,
          chatTextIcon: value,
          chatText: this.chatText,
          chatTextHref: this.chatTextHref,
        });
      },
    },
    chatText: {
      get() {
        return this.formData.meetingChat?.chatText || '';
      },
      set(value) {
        this.$emit('update-meeting-chat', {
          meetingImageUrl: this.meetingImageUrl,
          meetingImageHref: this.meetingImageHref,
          chatTextIcon: this.chatTextIcon,
          chatText: value,
          chatTextHref: this.chatTextHref,
        });
      },
    },
    chatTextHref: {
      get() {
        return this.formData.meetingChat?.chatTextHref || '';
      },
      set(value) {
        this.$emit('update-meeting-chat', {
          meetingImageUrl: this.meetingImageUrl,
          meetingImageHref: this.meetingImageHref,
          chatTextIcon: this.chatTextIcon,
          chatText: this.chatText,
          chatTextHref: value,
        });
      },
    },
    absenceText: {
      get() {
        return this.formData.plannedAbsence?.text || '';
      },
      set(value) {
        this.$emit('update-planned-absence', {
          text: value,
          date: this.absenceDate,
        });
      },
    },
    absenceDate: {
      get() {
        return this.formData.plannedAbsence?.date || '';
      },
      set(value) {
        this.$emit('update-planned-absence', {
          text: this.absenceText,
          date: value,
        });
      },
    },
  },
  methods: {
    toggleSection(section) {
      this.sections[section] = !this.sections[section];
      const sectionRef = `${section}Section`;
      if (this.sections[section]) {
        this.$refs[sectionRef].style.height = this.$refs[sectionRef].scrollHeight + 'px';
        this.$refs[sectionRef].style.paddingTop = '15px';
        this.$refs[sectionRef].style.paddingBottom = '15px';
      } else {
        this.$refs[sectionRef].style.height = '0';
        this.$refs[sectionRef].style.paddingTop = '0';
        this.$refs[sectionRef].style.paddingBottom = '0';
      }
    },
    clearLink() {
      this.linkName = '';
      this.linkUrl = '';
    },
    clearBanner() {
      this.bannerUrl = '';
      this.bannerHref = '';
    },
    clearDisclaimer() {
      this.disclaimer = '';
    },
    clearFeedback() {
      this.feedbackQuestion = '';
      this.feedbackLikeIcon = '';
      this.feedbackLikeLink = '';
      this.feedbackDislikeIcon = '',
      this.feedbackDislikeLink = '';
    },
    clearMeetingChat() {
      this.meetingImageUrl = '';
      this.meetingImageHref = '';
      this.chatTextIcon = '';
      this.chatText = '';
      this.chatTextHref = '';
    },
    clearPlannedAbsence() {
      this.absenceText = '';
      this.absenceDate = '';
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.header {
  margin-bottom: 20px;
}

.header h2 {
  font-size: 1.5em;
  color: #555;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  transition: color 0.3s, border-color 0.3s;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-option {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  background-color: #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-header:hover {
  background-color: #ddd;
}

.btn-remove {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
  transition: background-color 0.3s;
}

.btn-remove:hover {
  background-color: #d32f2f;
}

.addon-form {
  margin-top: -1px;
  padding: 0 15px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  border-top: none;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease, padding-top 0.3s ease, padding-bottom 0.3s ease;
}

.addon-form.active {
  height: auto;
  overflow: hidden;
}

.option label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #666;
  transition: color 0.5s;
}

.text-input, textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
  background-color: #f1f1f1;
  color: #333;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

.input-icon {
  display: flex;
  align-items: center;
}

.input-icon .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

.input-icon img.icon-square {
  width: 24px;
  height: 24px;
}

.with-icon {
  flex: 1;
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}

.transparent-button {
  background-color: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  color: #666;
  font-size: 0.9em;
  transition: background-color 0.3s ease, color 0.5s, border-color 0.5s;
  align-self: flex-end;
}

.transparent-button:hover {
  background-color: #eee;
}

.text-input:focus {
  border-color: #FF6A00;
  outline: none;
}

input[type="text"]:focus {
  outline: none;
  border-color: #FF6A00;
}

.dark-mode input[type="text"]:focus {
  border-color: #c15000;
}

.dark-mode .header h2 {
  color: #ddd;
  border-color: #555;
}

.dark-mode .option label {
  color: #ddd;
}

.dark-mode .text-input, .dark-mode textarea, .dark-mode .icon-container {
  background-color: #333;
  color: #ddd;
  border-color: #555;
}

.dark-mode .transparent-button {
  color: #ddd;
  border-color: #555;
}

.dark-mode .transparent-button:hover {
  background-color: #555;
}

.dark-mode .dropdown-header {
  background-color: #444;
  border-color: #555;
  color: #ddd;
}

.dark-mode .dropdown-header:hover {
  background-color: #555;
}

.dark-mode .clear-icon {
  color: #ddd;
}

.dark-mode .clear-icon:hover {
  color: #fff;
}

.dark-mode .addon-form {
  border-color: #555;
  background-color: #444;
}
</style>