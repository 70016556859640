import { createRouter, createWebHistory } from 'vue-router';
import MainPanel from '@/pages/generator/MainPanel.vue';
import BasicInfo from './views/GeneratorBasicInfo.vue';
import SocialMedia from './views/GeneratorSocialMedia.vue';
import Options from './views/GeneratorOptions.vue';
import Templates from './views/GeneratorTemplates.vue';
import Addons from './views/GeneratorAddons.vue';
import FeedbackPositive from '@/pages/feedback/FeedbackPositive.vue';
import FeedbackNegative from '@/pages/feedback/FeedbackNegative.vue';
import AdminPanel from '@/pages/generator/AdminPanel.vue';

const routes = [
  {
    path: '/',
    component: MainPanel,
    children: [
      {
        path: '',
        redirect: '/signature-templates',
      },
      {
        path: '/basic-info',
        component: BasicInfo,
      },
      {
        path: '/signature-templates',
        component: Templates,
      },
      {
        path: '/signature-options',
        component: Options,
      },
      {
        path: '/social-media',
        component: SocialMedia,
      },
      {
        path: '/signature-addons',
        component: Addons,
      },
    ],
  },
  {
    path: '/preview/:id',
    component: MainPanel,
  },
  {
    path: '/settings/:id',
    component: MainPanel,
  },
  {
    path: '/dev/:id',
    component: MainPanel,
  },
  { path: '/positive', component: FeedbackPositive },
  { path: '/negative', component: FeedbackNegative },
  { path: '/admin', component: AdminPanel },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = 'Generator | gSignature e-mail signatures';
  next();
});

export default router;