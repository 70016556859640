<template>
    <div class="powered-container">
      <span>Powered by</span>
      <a href="https://www.gsignature.com" target="_blank" rel="noopener noreferrer">
        <img :src="currentLogo" alt="Powered Logo" />
      </a>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isDarkMode: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      currentLogo() {
        return this.isDarkMode
        ? require('../../assets/images/logo/logo_gsignature_light.svg')
        : require('../../assets/images/logo/logo_gsignature_dark.svg');
      },
    },
  };
  </script>
  
  <style scoped>
  .powered-container {
    position: absolute;
    bottom: 30px;
    right: 60px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: var(--text-color);
  }
  
  .powered-container span {
    margin-right: 5px;
  }
  
  .powered-container img {
    height: 20px;
  }
  
  :root {
    --text-color: #333;
  }
  
  .dark-mode :root {
    --text-color: #ddd;
  }
  </style>
