<template>
  <div>
    <div class="header">
      <h2>Select a Template</h2>
    </div>
    <div class="template-grid">
      <button
        v-for="(template, key) in templates"
        :key="key"
        @click="selectTemplate(key)"
        :class="{ 'active': key === selectedTemplateName }"
        class="template-button"
      >
        <div v-if="loadingStates[key]" class="loader-container">
          <LoaderApp :isLoading="loadingStates[key]" />
        </div>
        <img
          v-else
          :src="template.image"
          alt="Template Image"
          class="template-image"
          @load="handleImageLoad(key)"
          @error="handleImageError(key)"
        />
      </button>
    </div>
  </div>
</template>

<script>
import LoaderApp from '../../components/loader/LoaderView.vue';

export default {
  props: {
    formData: Object,
    selectedTemplate: String,
  },
  components: {
    LoaderApp,
  },
  data() {
    return {
      templates: {
        template1: { name: 'Template 1', image: require('@/assets/images/templates/template1.png') },
        template2: { name: 'Template 2', image: require('@/assets/images/templates/template2.png') },
        template3: { name: 'Template 3', image: require('@/assets/images/templates/template3.png') },
        template4: { name: 'Template 4', image: require('@/assets/images/templates/template4.png') },
        template5: { name: 'Template 5', image: require('@/assets/images/templates/template5.png') },
      },
      selectedTemplateName: this.selectedTemplate || 'template1',
      loadingStates: {
        template1: true,
        template2: true,
        template3: true,
        template4: true,
        template5: true,
      },
    };
  },
  watch: {
    selectedTemplate(newVal) {
      this.selectedTemplateName = newVal || 'template1';
    }
  },
  mounted() {
    Object.keys(this.templates).forEach(key => {
      const img = new Image();
      img.src = this.templates[key].image;
      img.onload = () => this.handleImageLoad(key);
      img.onerror = () => this.handleImageError(key);
    });
  },
  methods: {
    selectTemplate(templateName) {
      this.selectedTemplateName = templateName;
      this.$emit('template-selected', templateName);
    },
    handleImageLoad(templateKey) {
      this.loadingStates[templateKey] = false;
    },
    handleImageError(templateKey) {
      console.error(`Failed to load image for template ${templateKey}`);
      this.loadingStates[templateKey] = false;
    },
  },
};
</script>

<style scoped>
.header {
  margin-bottom: 20px;
}

.header h2 {
  font-size: 1.5em;
  color: #555;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
  transition: color 0.3s, border-color 0.3s;
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
  width: 100%;
}

.template-button {
  position: relative;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.2s, background-color 0.3s;
  width: 100%;
  max-width: 400px;
  text-align: center;
  min-height: 150px;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.template-button:hover {
  transform: scale(1.05);
}

.template-button.active {
  border-color: #FF6A00;
  transform: scale(1.05);
}

.template-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .header h2 {
    font-size: 1.2em;
  }
}

.dark-mode .header h2 {
  color: #ddd;
  border-color: #555;
}

.dark-mode .template-button {
  background-color: #333;
  border-color: #555;
  color: #ddd;
}

.dark-mode .template-button:hover {
  background-color: #444;
}

.dark-mode .template-button.active {
  border-color: #c15000;
}

.dark-mode .template-image {
  background-color: #444;
}
</style>
